import TelaContrato from "../../components/TelaContratos/Index.jsx";

const ConfPedido = () => {
  return (
    <>
      <TelaContrato />
    </>
  );
};

export default ConfPedido;
